import React from "react";
import PropTypes from "prop-types";
import {Select} from "antd";
import {actionCreators as productActions, selector as productSelector} from "features/product";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import fuzzySort from "utils/fuzzySort";

function ProductSelect({product, isProductLoading, productActions}) {
  if (product.get("products") === null && !isProductLoading) {
    productActions.list({});
  } else if (product.get("products") !== null && product.get("selectedProductIds") === null) {
    const productIds = [];
    if (product.get("products").size === 1 && product.get("products").first()) {
      productIds.push(product.get("products").first().get("productId"));
    }
    productActions.setSelected({productIds});
  }

  const options = [];
  const searchQuery = product.get("searchQuery");

  if (product.get("products")) {
    product.get("products")
      .sort((a, b) => fuzzySort({a, b, searchQuery, pathToValue: ["name"]}))
      .forEach((product) => {
        options.push(
          <Select.Option
            key={product.get("productId")}
            value={product.get("productId")}
            title={product.get("name")}
          >
            {product.get("name")}
          </Select.Option>,
        );
      });
  }

  return (
    <Select
      mode="multiple"
      style={{width: "100%"}}
      placeholder="Select Product"
      value={product.get("selectedProductIds") ? product.get("selectedProductIds").toArray() : []}
      onChange={(productIds) => productActions.setSelected({productIds})}
      showSearch
      showClear
      filterOption={(searchQuery) => productActions.setSearchquery({searchQuery})}
      loading={isProductLoading}
    >
      {options}
    </Select>
  );
}

ProductSelect.propTypes = {
  product: PropTypes.object.isRequired,
  productActions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    ...productSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    productActions: bindActionCreators(productActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductSelect);
