import React from 'react';
import PropTypes from 'prop-types';
import {Layout} from 'antd';
import NavigationTop from 'components/NavigationTop';

const {Footer, Content} = Layout;

function AppLayout({children}) {
  return (
    <Layout>
      <NavigationTop/>
      <Content>
        {children}
      </Content>
      <Footer>
        <div style={{textAlign: 'center', lineHeight: '32px'}}>
          &copy; Pitchground.com
        </div>
      </Footer>
    </Layout>
  );
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppLayout;
