import {Card, Spin, Statistic} from "antd";
// import {bindActionCreators} from 'redux';
import {selector as statsSelector} from "features/stats";
import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";

const numeral = require("numeral");

function StatsTotalRefunds({stats}) {
  const isLoading = stats.get("stats") === null;
  let totalRefund = 0;
  let totalSales = 0;
  let refundCount = 0;
  if (isLoading === false) {
    stats.get("stats").forEach((stat) => {
      totalSales += stat.get("priceForOne");
      if (stat.get("state") === "refunded") {
        totalRefund += stat.get("priceForOne");
        refundCount++;
      }
    });
  }
  const percentRaw = totalRefund / totalSales;
  let refundPercent = totalSales ? numeral(percentRaw).format("0,0[.]000%") : "";
  let percentColor = percentRaw >= 0.15 ? "red" : "";

  return (
    <Card>
      <Statistic
        title={isLoading ? <Spin size={"small"}/> : `Refunds (${refundCount})`}
        value={totalRefund}
        precision={0}
        prefix="$"
        suffix={refundPercent ? <>(<span style={{color: percentColor}}>{refundPercent}</span>)</> : ""}
      />
    </Card>
  );
}

StatsTotalRefunds.propTypes = {
  stats: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    ...statsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // productActions: bindActionCreators(productActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StatsTotalRefunds);
