const {fuzzy} = require('fast-fuzzy');
export default function fuzzySort({a, b, searchQuery, pathToValue}) {
  {
    const fuzzyA = fuzzy(searchQuery, a.getIn(pathToValue));
    const fuzzyB = fuzzy(searchQuery, b.getIn(pathToValue));

    if (fuzzyA > fuzzyB) {
      return -1;
    } else if (fuzzyA < fuzzyB) {
      return 1;
    }
    return 0;
  }
}