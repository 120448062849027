import {Button} from "antd";
import {selector as productSelector} from "features/product";
import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";
import config from "utils/../config";

const queryString = require("query-string");

function BtnCsvExport({product}) {
  const productIds = product.get("selectedProductIds") ? product.get("selectedProductIds").toJS() : [];

  if (productIds.length === 0) {
    return (
      <Button disabled>
        Get CSV export
      </Button>
    );
  }

  const payload = {
    authToken: localStorage.getItem("authToken"),
    productIds,
    type: "csv",
  };

  const url = `${config.API_ENDPOINT}/v2/vendor/product/get-stats?${queryString.stringify(payload)}`;

  return (
    <>
      <a href={`${url}`} target={"_blank"} rel={"noreferrer"}>
        <Button style={{margin: '0 3px'}}>
          Get CSV export (all)
        </Button>
      </a>
      <a href={`${url}&filter=refunds-only`} target={"_blank"} rel={"noreferrer"}>
        <Button style={{margin: '0 3px'}}>
          Get CSV export (refunds only)
        </Button>
      </a>
      <a href={`${url}&filter=sales-only`} target={"_blank"} rel={"noreferrer"}>
        <Button style={{margin: '0 3px'}}>
          Get CSV export (sales only)
        </Button>
      </a>
    </>
  );
}

BtnCsvExport.propTypes = {
  product: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    ...productSelector(state),
  };
}

export default connect(
  mapStateToProps,
)(BtnCsvExport);
