/* eslint-disable */
import {createStructuredSelector} from 'reselect';
import userLogout from 'utils/userLogout';

const {Map, fromJS} = require('immutable');

export const NAME = 'user'; // name in redux store

const GET_TRY = 'user/GET_TRY';
const GET_SUCCESS = 'user/GET_SUCCESS';
const GET_FAILED = 'user/GET_FAILED';

const LOGIN_TRY = 'user/LOGIN_TRY';
const LOGIN_SUCCESS = 'user/LOGIN_SUCCESS';
const LOGIN_FAILED = 'user/LOGIN_FAILED';

const LOGOUT_TRY = 'user/LOGOUT_TRY';
const LOGOUT_SUCCESS = 'user/LOGOUT_SUCCESS';
const LOGOUT_FAILED = 'user/LOGOUT_FAILED';

const initialState = Map({
  user: null, // when logged in - map with all user's data
  token: null, // when logged in - string
  isGetLoading: false,
  isLoginLoading: false,
  isLogoutLoading: false,
});

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TRY:
      return state.set('isGetLoading', true);
    case GET_FAILED:
      return state.set('isGetLoading', false);
    case GET_SUCCESS:
      return state.set('isGetLoading', false).set('user', fromJS(action.data));

    case LOGIN_TRY:
      return state.set('isLoginLoading', true).set('preLoginEmail', fromJS(action.data.email));
    case LOGIN_FAILED:
      return state.set('isLoginLoading', false);
    case LOGIN_SUCCESS:
      return state.set('isLoginLoading', false).set('token', action.data.token);

    case LOGOUT_TRY:
      return state.set('isLogoutLoading', true);
    case LOGOUT_FAILED:
      return state.set('isLogoutLoading', false);
    case LOGOUT_SUCCESS:
      return state.set('isLogoutLoading', false).set('token', action.data.token).set('user', null);

    default:
      return state;
  }
}

// #### ACTIONS ####
function login({email, password,recaptchaToken}) {
  return {type: LOGIN_TRY, data: {email, password,recaptchaToken}};
}

function logout({}) {
  userLogout();
  return {type: '', data: {}};
}

function get({}) {
  return {type: GET_TRY, data: {}};
}

// #### SELECTORS ####
const user = (state) => state[NAME];
const isLoggedIn = (state) => Boolean(state[NAME].get('token'));

function isUserLoading(state) {
  return (
    state[NAME].get('isLoginLoading')
    || state[NAME].get('isLogoutLoading')
    || state[NAME].get('isGetLoading')
  );
}

export const selector = createStructuredSelector({
  user,
  isLoggedIn,
  isUserLoading,
});

export const actionCreators = {
  login,
  logout,
  get,
};

export const actionTypes = {
  GET_TRY,
  GET_SUCCESS,
  GET_FAILED,
  LOGIN_TRY,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_TRY,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
};
