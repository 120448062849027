import React from 'react';
import {Provider} from 'react-redux';
import getStore from 'store/getStore';
import {ConnectedRouter} from 'connected-react-router';
import Home from 'components/Home';
import Dashboard from 'components/Dashboard';
import Settings from 'components/Settings';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import '../theme.css';

function Root() {
  const {store, history} = getStore();
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Router>
          <Route path={'/'} exact component={Home}/>
          <Route path={'/dashboard'} exact component={Dashboard}/>
          <Route path={'/settings'} exact component={Settings}/>
        </Router>
      </ConnectedRouter>
    </Provider>
  );
}

export default Root;
