import configureStore from './configureStore';

let store = null;
let history = null;
const initialState = {};

export default function getStore() {
  if (store === null) {
    const result = configureStore({initialState});
    store = result.store;
    history = result.history;
  }

  return {
    store,
    history,
  };
}