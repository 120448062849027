import {put, takeLatest, takeEvery} from 'redux-saga/effects';
import {actionTypes as userActions} from 'features/user';
import {actionTypes as productActions} from 'features/product';
import {actionTypes as statsActions} from 'features/stats';
import snackbar from 'utils/snackbar';

import tryUserLogin from './user/login';
import tryUserLogout from './user/logout';
import tryUserGet from './user/get';
import afterLoginSuccess from './user/afterLoginSuccess';

import tryProductList from './product/list';

import tryStatsGet from './stats/get';
import afterProductSelectionChanged from './product/afterProductSelectionChanged';

export default function* rootSaga() {
  yield takeLatest(userActions.LOGIN_TRY, tryUserLogin);
  yield takeEvery(userActions.LOGOUT_TRY, tryUserLogout);
  yield takeLatest(userActions.LOGIN_SUCCESS, afterLoginSuccess);
  yield takeLatest(userActions.GET_TRY, tryUserGet);
  yield takeLatest(productActions.LIST_TRY, tryProductList);
  yield takeLatest(productActions.SET_SELECTED, afterProductSelectionChanged);
  yield takeLatest(statsActions.GET_TRY, tryStatsGet);

  if (typeof localStorage !== 'undefined') {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      snackbar({message: 'Welcome back'});
      yield put({type: userActions.LOGIN_SUCCESS, data: {authToken}});
    }
  }
}
