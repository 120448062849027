import {actionTypes as userTypes} from "features/user";
import {put} from "redux-saga/effects";
import snackbar from "utils/snackbar";
import sdk from "../../utils/getSdk";

const _get = require("lodash/get");

export default function* tryUserLogin({data}) {
  const result = yield sdk.userLogin({
    email: data.email,
    password: data.password,
    recaptchaToken: data.recaptchaToken,
  });
  const authToken = _get(result, "data.authToken");

  if (authToken) {
    localStorage.setItem("authToken", authToken);
    yield put({type: userTypes.LOGIN_SUCCESS, data: result.data});
    snackbar({message: "Logged in"});
  } else {
    yield put({type: userTypes.LOGIN_FAILED, data: {error: result.error}});
    snackbar({message: _get(result, "error.message"), intent: "error"});
  }
}
