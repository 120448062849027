import {put} from 'redux-saga/effects';
import {actionTypes as userTypes} from 'features/user';
import sdk from '../../utils/getSdk';
import userLogout from 'utils/userLogout';

const _get = require('lodash/get');

export default function* tryUserGet({data}) {
  const result = yield sdk.userGet({authToken: localStorage.getItem('authToken')});
  if (result.error) {
    yield put({type: userTypes.GET_FAILED, data: {error: result.error}});
    if (_get(result, 'error')) {
      userLogout();
    }
  } else {
    if (!result.data) {
      console.error(`#34rggehth FAILED to get user! `, result);
    } else {
      yield put({type: userTypes.GET_SUCCESS, data: result.data});
    }
  }
}
