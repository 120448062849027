import {applyMiddleware, createStore, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'sagas/rootSaga';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import createRootReducer from './reducers';

const history = createBrowserHistory();

export default function configureStore({initialState}) {
  const sagaMiddleware = createSagaMiddleware();

  const isDevToolsAvailable = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const composeEnchancers = isDevToolsAvailable
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({maxAge: 200})
    : compose;

  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnchancers(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        sagaMiddleware,
      ),
    ),
  );

  store.runSagaTask = () => {
    store.sagaTask = sagaMiddleware.run(rootSaga);
  };

  store.runSagaTask();

  return {
    store,
    history,
  };
}



