import React from 'react';
import Layout from 'components/Layout';
import UserLogin from 'features/user/components/login';

function Home() {
  return (
    <Layout>
      <div style={{margin: '0 48px'}}>
        <UserLogin/>
      </div>
    </Layout>
  );
}

Home.propTypes = {};

export default Home;
