import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {selector as statsSelector, actionCreators as statsActions} from 'features/stats';
import {selector as productSelector} from 'features/product';
import {Button} from 'antd';

function StatsRefresh({stats, statsActions, isStatsLoading, product}) {
  const isLoading = stats.get('stats') === null || isStatsLoading;
  const productIds = product.get('selectedProductIds') ? product.get('selectedProductIds').toJS() : [];

  return (
    <Button
      disabled={!product.get('selectedProductIds') || product.get('selectedProductIds').size === 0}
      loading={isLoading}
      onClick={() => statsActions.get({productIds})}
    >
      Refresh
    </Button>
  );
}

StatsRefresh.propTypes = {
  stats: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    ...statsSelector(state),
    ...productSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    statsActions: bindActionCreators(statsActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StatsRefresh);
