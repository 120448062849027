import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Row, Col} from 'antd';
import {selector as productSelector} from 'features/product';
import Layout from 'components/Layout';
import ProductSelect from 'features/product/components/select';
import StatsRefresh from 'features/stats/components/refresh.js';
import StatsTimeseries from 'features/stats/components/timeseries';
import StatsTotalSales from 'features/stats/components/totalSales';
import StatsTotalRefunds from 'features/stats/components/totalRefunds';
import StatsTotalBalance from 'features/stats/components/totalBalance';
import BtnCsvExport from 'features/stats/components/btnCsvExport';

function Dashboard({product}) {
  return (
    <Layout>
      <div style={{maxWidth: 1000, margin: '24px auto', padding: '0 6px'}}>

        <div style={{margin: '24px 0', display: 'flex'}}>
          <ProductSelect/>
          <div style={{margin: 12}}/>
          <StatsRefresh/>
        </div>
        {
          !product.get('selectedProductIds') || product.get('selectedProductIds').size > 0 ? (
            <div>
              <Row>
                <Col xs={8} md={8}>
                  <StatsTotalSales/>
                </Col>
                <Col xs={8} md={8}>
                  <StatsTotalRefunds/>
                </Col>
                <Col xs={8} md={8}>
                  <StatsTotalBalance/>
                </Col>
                {/*<Col xs={12} md={6}>*/}
                {/*  <StatsTotalPayout/>*/}
                {/*</Col>*/}
              </Row>
              <div style={{textAlign: 'right', margin: '24px 0'}}>
                <BtnCsvExport/>
              </div>
              <Row>
                <Col>
                  <div style={{margin: '24px 0'}}>
                    <StatsTimeseries/>
                  </div>
                </Col>
              </Row>
            </div>
          ) : null
        }
      </div>
    </Layout>
  );
}

Dashboard.propTypes = {
  product: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    ...productSelector(state),
  };
}

export default connect(
  mapStateToProps,
)(Dashboard);
