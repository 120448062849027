/* eslint-disable */
import {createStructuredSelector} from 'reselect';

const {Map, fromJS, Set} = require('immutable');

export const NAME = 'product'; // name in redux store

const GET_TRY = 'product/GET_TRY';
const GET_SUCCESS = 'product/GET_SUCCESS';
const GET_FAILED = 'product/GET_FAILED';

const LIST_TRY = 'product/LIST_TRY';
const LIST_SUCCESS = 'product/LIST_SUCCESS';
const LIST_FAILED = 'product/LIST_FAILED';

const SET_SELECTED = 'product/SET_SELECTED';
const SEARCH_QUERY = 'product/SEARCH_QUERY';

const initialState = Map({
  selectedProductIds: null, // Set on selection. Can select several products
  searchQuery: '', // search for product to select
  product: null, // Map if loaded already
  products: null, // List if loaded already
  isGetLoading: false,
  isListLoading: false,
});

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TRY:
      return state.set('isGetLoading', true);
    case GET_FAILED:
      return state.set('isGetLoading', false);
    case GET_SUCCESS:
      return state.set('isGetLoading', false).set('product', fromJS(action.data));

    case LIST_TRY:
      return state.set('isGetLoading', true);
    case LIST_FAILED:
      return state.set('isGetLoading', false);
    case LIST_SUCCESS:
      return state.set('isGetLoading', false).set('products', fromJS(action.data));

    case SET_SELECTED:
      return state.set('selectedProductIds', Set(action.data.productIds));
    case SEARCH_QUERY:
      return state.set('searchQuery', action.data.searchQuery);

    default:
      return state;
  }
}

// #### ACTIONS ####

function get({productId}) {
  return {type: GET_TRY, data: {productId}};
}

function list({}) {
  return {type: LIST_TRY, data: {}};
}

function setSelected({productIds}) {
  return {type: SET_SELECTED, data: {productIds}};
}

function setSearchquery({searchQuery}) {
  return {type: SEARCH_QUERY, data: {searchQuery}};
}

// #### SELECTORS ####
const product = (state) => state[NAME];
const isProductLoading = (state) => state[NAME].get('isGetLoading') || state[NAME].get('isListLoading');

export const selector = createStructuredSelector({
  product,
  isProductLoading,
});

export const actionCreators = {
  get,
  list,
  setSelected,
  setSearchquery,
};

export const actionTypes = {
  GET_TRY,
  GET_SUCCESS,
  GET_FAILED,
  LIST_TRY,
  LIST_SUCCESS,
  LIST_FAILED,
  SET_SELECTED,
  SEARCH_QUERY,
};
