import {message as antMsg} from 'antd';

antMsg.config({
  duration: 1,
  top:5
});

export default function snackbar({message, intent = 'success'}) {
  if (['success', 'error', 'warning'].includes(intent) === false) {
    console.warn('#d32ewkhfw ', intent);
    intent = 'success';
  }
  antMsg[intent](message);
}