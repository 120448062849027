import React from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {selector as statsSelector, actionCreators as statsActions} from 'features/stats';
import {selector as productSelector} from 'features/product';

function ChartCount({options}) {
  return (
    <ReactEcharts
      option={options}
      notMerge={true}
      lazyUpdate={true}
    />
  );
}

ChartCount.propTypes = {
  options: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    ...statsSelector(state),
    ...productSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    statsActions: bindActionCreators(statsActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChartCount);
