import {Map} from 'immutable';

const moment = require('moment');

export default function getOptionsGlobal({stats}) {
  const salesTotal = [];
  const refundTotal = [];
  const balanceTotal = [];
  const salesCount = [];
  const refundCount = [];
  const balanceCount = [];
  const salesSum = [];
  const refundSum = [];
  const balanceSum = [];

  const finalAllStats = {};
  let salesSumOfAll = 0;
  let refundSumOfAll = 0;
  let balanceSumOfAll = 0;

  const defaultFill = {
    balanceTotal: 0,
    salesTotal: 0,
    refundTotal: 0,
    balanceCount: 0,
    salesCount: 0,
    refundCount: 0,
  };

  if (stats) {
    const sortedStats = stats.sort((a, b) => {
      if (a.get('paidAt') > b.get('paidAt')) {
        return 1;
      } else if (a.get('paidAt') < b.get('paidAt')) {
        return -1;
      }
      return 0;
    });

    const startAt = sortedStats.first();
    if (typeof startAt !== 'undefined') {
      let timeWindow = moment.unix(startAt.get('paidAt')).startOf('hour');

      while (moment().diff(timeWindow) > 0) {
        finalAllStats[timeWindow.unix()] = {...defaultFill};
        timeWindow.add(1, 'hour');
      }

      sortedStats.forEach((stat) => {
        // stat = {
        //   buyerCountry: 'IN',
        //   priceForOne: 98,
        //   quantity: 1,
        //   product: 'Apploye',
        //   state: 'sold',
        //   couponCode: 'PGLTD-MQGefVWVSH',
        //   priceTotal: 98,
        //   buyerName: 'Ashin John',
        //   paidAt: 1565084627,
        // };

        const startOfHour = moment.unix(stat.get('paidAt')).startOf('hour').unix();
        // console.log(`#fljwfek stat.get('priceTotal') `, stat.get('priceTotal'));
        salesSumOfAll += stat.get('priceForOne');
        finalAllStats[startOfHour].salesTotal += stat.get('priceForOne');
        finalAllStats[startOfHour].salesCount++;

        if (stat.get('state') === 'sold') {
          balanceSumOfAll += stat.get('priceForOne');
          finalAllStats[startOfHour].balanceTotal += stat.get('priceForOne');
          finalAllStats[startOfHour].balanceCount++;
        } else if (stat.get('state') === 'refunded') {
          refundSumOfAll += stat.get('priceForOne');
          finalAllStats[startOfHour].refundTotal += stat.get('priceForOne');
          finalAllStats[startOfHour].refundCount++;
        }

        finalAllStats[startOfHour].balanceSum = balanceSumOfAll;
        finalAllStats[startOfHour].salesSum = salesSumOfAll;
        finalAllStats[startOfHour].refundSum = refundSumOfAll;
      });
    }
  }

  const xAxisData = [];
  Object.keys(finalAllStats)
    .sort((a, b) => {
      if (a > b) {
        return 1;
      } else if (a < b) {
        return -1;
      }
      return 0;
    }).forEach((key) => {
    xAxisData.push(key);
    salesTotal.push(finalAllStats[key].salesTotal);
    salesCount.push(finalAllStats[key].salesCount);
    refundTotal.push(finalAllStats[key].refundTotal);
    refundCount.push(finalAllStats[key].refundCount);
    balanceSum.push(finalAllStats[key].balanceSum);
    salesSum.push(finalAllStats[key].salesSum);
    refundSum.push(finalAllStats[key].refundSum);
    balanceTotal.push(finalAllStats[key].balanceTotal);
    balanceCount.push(finalAllStats[key].balanceCount);
  });

  const optionsChart = Map({
    title: {
      x: 'center',
      align: 'right',
    },
    grid: {
      bottom: 80,
    },
    toolbox: {
      feature: {
        saveAsImage: {
          title: 'PNG',
          name: 'Pitchground-chart',
        },
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
        animation: false,
        label: {
          backgroundColor: '#505765',
        },
      },
    },
    legend: {
      // data: ['Sales', 'Refunds', 'Balance', 'Balance Sum', 'Sales Count', 'Refund Count', 'Balance Count'],
      // selected: {
      //   'Sales': false,
      //   'Refunds': false,
      //   'Balance': false,
      //   'Balance Sum': true,
      //   'Sales Count': false,
      //   'Refund Count': false,
      //   'Balance Count': false,
      // },
      height: 200,
      x: 'left',
    },
    dataZoom: [
      {
        type: 'slider',
        show: true,
        realtime: true,
        start: 0,
        end: 100,
        labelFormatter: (x, value) => moment.unix(value).format('MMM, DD HH:mm'),
      },
      {
        type: 'inside',
        realtime: true,
        start: 0,
        end: 100,
      },
    ],
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        axisLine: {onZero: false},
        data: xAxisData,
        axisPointer: {
          label: {
            formatter: (params) => moment.unix(params.value).format('MMM, DD HH:mm'),
          },
        },
        axisLabel: {
          formatter: (value) => {
            const mm = moment.unix(value);
            const line1 = mm.format('MMM, DD');
            const line2 = mm.format('HH:mm');
            return `${line1}\n${line2}`;
          },
        },
      },
    ],
    // yAxis: [
    //   {
    //     name: 'USD',
    //     type: 'value',
    //     axisLabel: {
    //       formatter: (value) => numeral(value).format('$0,0'),
    //     },
    //     axisPointer: {
    //       label: {
    //         formatter: (params) => numeral(params.value).format('$0,0'),
    //       },
    //     },
    //   },
    //   {
    //     name: 'Count',
    //     type: 'value',
    //     axisLabel: {
    //       formatter: (value) => numeral(value).format('0,0'),
    //     },
    //     axisPointer: {
    //       label: {
    //         formatter: (params) => numeral(params.value).format('0,0'),
    //       },
    //     },
    //
    //   },
    // ],
    // series: [
    //   {
    //     name: 'Sales',
    //     type: 'line',
    //     yAxisIndex: 0,
    //     animation: true,
    //     areaStyle: {},
    //     lineStyle: {
    //       width: 1,
    //     },
    //     data: salesTotal,
    //     label: {
    //       formatter: (value) => console.log(`#lejklfjw `, value),
    //     },
    //
    //   },
    //   {
    //     name: 'Refunds',
    //     type: 'line',
    //     yAxisIndex: 0,
    //     animation: false,
    //     areaStyle: {},
    //     lineStyle: {
    //       width: 1,
    //     },
    //     data: refundTotal,
    //   },
    //   {
    //     name: 'Balance',
    //     type: 'line',
    //     yAxisIndex: 0,
    //     animation: false,
    //     areaStyle: {},
    //     lineStyle: {
    //       width: 1,
    //     },
    //     data: balanceTotal,
    //   },
    //   {
    //     name: 'Balance Sum',
    //     type: 'line',
    //     yAxisIndex: 0,
    //     animation: false,
    //     connectNulls: true,
    //     areaStyle: {},
    //     lineStyle: {
    //       width: 1,
    //     },
    //     data: balanceSum,
    //   },
    //   {
    //     name: 'Sales Count',
    //     type: 'line',
    //     yAxisIndex: 1,
    //     animation: true,
    //     areaStyle: {},
    //     lineStyle: {
    //       width: 1,
    //     },
    //     data: salesCount,
    //   },
    //   {
    //     name: 'Refund Count',
    //     type: 'line',
    //     yAxisIndex: 1,
    //     animation: false,
    //     areaStyle: {},
    //     lineStyle: {
    //       width: 1,
    //     },
    //     data: refundCount,
    //   },
    //   {
    //     name: 'Balance Count',
    //     type: 'line',
    //     yAxisIndex: 1,
    //     animation: false,
    //     areaStyle: {},
    //     lineStyle: {
    //       width: 1,
    //     },
    //     data: balanceCount,
    //   },
    // ],
  });

  return {
    data: {
      xAxisData,

      balanceTotal,
      salesTotal,
      refundTotal,

      balanceCount,
      salesCount,
      refundCount,

      balanceSum,
      salesSum,
      refundSum,
    },
    chart: optionsChart,
  };
}