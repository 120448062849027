import {combineReducers} from 'redux';
import userReducer, {NAME as userName} from 'features/user';
import statsReducer, {NAME as statsName} from 'features/stats';
import productReducer, {NAME as productName} from 'features/product';
import {connectRouter} from 'connected-react-router';

export default (history) => combineReducers({
  [userName]: userReducer,
  [statsName]: statsReducer,
  [productName]: productReducer,
  router: connectRouter(history),
});
