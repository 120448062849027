import React from 'react';
import Layout from 'components/Layout';
import WithUserOnly from 'components/hoc/WithUserOnly';

function Settings() {
  return (
    <Layout>
      Welcome to the Settings
    </Layout>
  );
}


export default WithUserOnly(Settings);
