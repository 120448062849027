const numeral = require('numeral');
const moment = require('moment');
const _get = require('lodash/get');

export default function getOptionsMoney({optionsGlobal}) {
  const salesTotal = _get(optionsGlobal, 'data.salesTotal');
  const refundTotal = _get(optionsGlobal, 'data.refundTotal');
  const balanceTotal = _get(optionsGlobal, 'data.balanceTotal');
  // const finalAllStats = _get(optionsGlobal, 'data.finalAllStats');
  // const xAxisData = _get(optionsGlobal, 'data.xAxisData');

  let charOptions = optionsGlobal.chart;
  charOptions = charOptions.setIn(['tooltip', 'formatter'], (values) => {
    const rows = [];
    const at = _get(values, '0.axisValue');
    rows.push(`<strong>${moment.unix(at).format('MMM, DD HH:mm')}</strong>`);
    values.forEach((value) => {
      rows.push(`<div style="background: ${value.color}; width: 10px; height: 10px; display: inline-block; margin-right: 12px"></div>${value.seriesName}: ${numeral(value.value).format('$0,0')}`);
    });
    return rows.join('<br />');
  });
  charOptions = charOptions.setIn(['title', 'textAlign'], 'right');
  charOptions = charOptions.setIn(['legend', 'data'], ['Balance', 'Sales', 'Refund']);
  charOptions = charOptions.setIn(['legend', 'selected'], {'Balance': true, 'Sales': false, 'Refund': false});
  charOptions = charOptions.setIn(['yAxis'], [
    {
      name: 'USD',
      type: 'value',
      axisLabel: {
        formatter: (value) => numeral(value).format('$0,0'),
      },
      axisPointer: {
        label: {
          formatter: (params) => numeral(params.value).format('$0,0'),
        },
      },
    },
  ]);

  charOptions = charOptions.setIn(['series'], [
    {
      name: 'Balance',
      type: 'line',
      yAxisIndex: 0,
      animation: false,
      areaStyle: {},
      lineStyle: {
        width: 1,
      },
      data: balanceTotal,
    },
    {
      name: 'Sales',
      type: 'line',
      yAxisIndex: 0,
      animation: true,
      areaStyle: {},
      lineStyle: {
        width: 1,
      },
      data: salesTotal,
    },
    {
      name: 'Refund',
      type: 'line',
      yAxisIndex: 0,
      animation: false,
      areaStyle: {},
      lineStyle: {
        width: 1,
      },
      data: refundTotal,
    },
  ]);
  return charOptions;

  // return {
  // yAxis: [
  //   {
  //     name: 'USD',
  //     type: 'value',
  //     axisLabel: {
  //       formatter: (value) => numeral(value).format('$0,0'),
  //     },
  //     axisPointer: {
  //       label: {
  //         formatter: (params) => numeral(params.value).format('$0,0'),
  //       },
  //     },
  //   },
  //   {
  //     name: 'Count',
  //     type: 'value',
  //     axisLabel: {
  //       formatter: (value) => numeral(value).format('0,0'),
  //     },
  //     axisPointer: {
  //       label: {
  //         formatter: (params) => numeral(params.value).format('0,0'),
  //       },
  //     },
  //
  //   },
  // ],
  // series: [
  //   {
  //     name: 'Sales',
  //     type: 'line',
  //     yAxisIndex: 0,
  //     animation: true,
  //     areaStyle: {},
  //     lineStyle: {
  //       width: 1,
  //     },
  //     data: salesTotal,
  //     label: {
  //       formatter: (value) => console.log(`#lejklfjw `, value),
  //     },
  //
  //   },
  //   {
  //     name: 'Refunds',
  //     type: 'line',
  //     yAxisIndex: 0,
  //     animation: false,
  //     areaStyle: {},
  //     lineStyle: {
  //       width: 1,
  //     },
  //     data: refundTotal,
  //   },
  //   {
  //     name: 'Balance',
  //     type: 'line',
  //     yAxisIndex: 0,
  //     animation: false,
  //     areaStyle: {},
  //     lineStyle: {
  //       width: 1,
  //     },
  //     data: balanceTotal,
  //   },
  //   {
  //     name: 'Balance Sum',
  //     type: 'line',
  //     yAxisIndex: 0,
  //     animation: false,
  //     connectNulls: true,
  //     areaStyle: {},
  //     lineStyle: {
  //       width: 1,
  //     },
  //     data: balanceSum,
  //   },
  //   {
  //     name: 'Sales Count',
  //     type: 'line',
  //     yAxisIndex: 1,
  //     animation: true,
  //     areaStyle: {},
  //     lineStyle: {
  //       width: 1,
  //     },
  //     data: salesCount,
  //   },
  //   {
  //     name: 'Refund Count',
  //     type: 'line',
  //     yAxisIndex: 1,
  //     animation: false,
  //     areaStyle: {},
  //     lineStyle: {
  //       width: 1,
  //     },
  //     data: refundCount,
  //   },
  //   {
  //     name: 'Balance Count',
  //     type: 'line',
  //     yAxisIndex: 1,
  //     animation: false,
  //     areaStyle: {},
  //     lineStyle: {
  //       width: 1,
  //     },
  //     data: balanceCount,
  //   },
  // ],
  // };
}