import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Redirect} from 'react-router-dom';
import {actionCreators as userActions, selector as userSelector} from 'features/user';
import {Form, Icon, Input, Button} from 'antd';
import Logo from 'components/Logo';
import ReCAPTCHA from 'react-google-recaptcha';

function UserLogin({userActions, user, isUserLoading}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const userId = user.getIn(['user', 'userId']);

  if (userId) {
    return <Redirect to={'/dashboard'}/>;
  }

  return (
    <div>
      <div style={{margin: '48px auto', maxWidth: 400, width: '100%', padding: '0 12px'}}>
        <div style={{textAlign: 'center', margin: '48px 18px 32px'}}>
          <Logo/>
        </div>

        <Form onSubmit={(e) => {
          e.preventDefault();
          userActions.login({email, password,recaptchaToken});
        }}>
          <Form.Item>
            <Input
              size={'large'}
              disabled={isUserLoading}
              prefix={<Icon type="mail"/>}
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Input
              size={'large'}
              disabled={isUserLoading}
              prefix={<Icon type="lock"/>}
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>
          <div style={{ margin: '12px 0' }}>
            <ReCAPTCHA
              sitekey={
                process.env.REACT_APP_RECAPTCHA_SITE_KEY ||
                "6LfrogAeAAAAAB3YuXeeeuAaWEKy-p6tZq2qqwnP"
              }
              onChange={(token) => {
                setRecaptchaToken(token);
              }}
            />
          </div>
          <Form.Item>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <Button
                disabled={!email || !password || !recaptchaToken}
                type="primary" htmlType="submit" loading={isUserLoading}>
                Log in
              </Button>
              <Button type={'link'}>
                <a
                  href="https://pitchground.com/deals?userflow=request_password_change"
                  target={'_blank'}
                >
                  Reset Password
                </a>
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

UserLogin.propTypes = {
  user: PropTypes.object.isRequired,
  userActions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    ...userSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserLogin);
