import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import snackbar from 'utils/snackbar';
import {selector as userSelector} from 'features/user';
import {Spin} from 'antd';

function WithUserOnly(WrappedComponent) {

  function ModifiedComponent({user, isUserLoading}) {
    const userId = user && user.getIn(['user', 'userId']);
    if (!userId && isUserLoading) {
      return <div style={{margin: 48, textAlign: 'center'}}><Spin size="large"/></div>;
    }

    if (isUserLoading === false && !userId) {
      snackbar({message: 'Please login', intent: 'error'});
      return <Redirect to={'/'}/>;
    }
    return <WrappedComponent user={user}/>;
  }

  ModifiedComponent.propTypes = {
    user: PropTypes.object.isRequired,
  };

  ModifiedComponent.propTypes = {
    user: PropTypes.object.isRequired,
  };

  function mapStateToProps(state) {
    return {
      ...userSelector(state),
    };
  }

  return connect(
    mapStateToProps,
  )(ModifiedComponent);

}

export default WithUserOnly;