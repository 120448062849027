import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// import {bindActionCreators} from 'redux';
import {selector as statsSelector} from 'features/stats';
import {Spin, Statistic, Card} from 'antd';

function StatsTotalBalance({stats}) {
  const isLoading = stats.get('stats') === null;
  let totalBalance = 0;
  let noneRefundCount = 0;
  if (isLoading === false) {
    stats.get('stats').forEach((stat) => {
      if (stat.get('state') === 'sold') {
        totalBalance += stat.get('priceForOne');
        noneRefundCount++;
      }
    });
  }

  return (
    <Card>
      <Statistic
        title={isLoading ? <Spin size={'small'}/> : `Balance (${noneRefundCount})`}
        value={totalBalance}
        precision={0}
        prefix="$"
      />
    </Card>
  );
}

StatsTotalBalance.propTypes = {
  stats: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    ...statsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // productActions: bindActionCreators(productActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StatsTotalBalance);
