import {Spin} from "antd";
import {selector as productSelector} from "features/product";
import {actionCreators as statsActions, selector as statsSelector} from "features/stats";
import { selector as userSelector} from "features/user";
import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ChartCount from "./chartCount";
import ChartMoney from "./chartMoney";
import ChartSum from "./chartSum";
import getOptionsCount from "./getOptionsCount";
import getOptionsGlobal from "./getOptionsGlobal";
import getOptionsMoney from "./getOptionsMoney";
import getOptionsSum from "./getOptionsSum";
import PayoutStats from "./PayoutStats";

function StatsTimeseries({stats, isStatsLoading,user}) {
  const isLoading = !stats || stats.get("stats") === null || isStatsLoading;
  if (isLoading) {
    return (
      <div style={{margin: "24px auto", textAlign: "center"}}>
        <Spin size={"large"}/>
      </div>
    );
  }

  let optionsGlobal = getOptionsGlobal({stats: stats.get("stats")});
  const optionsCount = getOptionsCount({optionsGlobal});
  const optionsMoney = getOptionsMoney({optionsGlobal});
  const optionsSum = getOptionsSum({optionsGlobal});

  return (
    <div>
      <div>
        <div style={{height: 48}}/>
        <h2 style={{textAlign: "center", marginBottom: 0}}>Stats in 30d periods</h2>
        <PayoutStats stats={stats} user={user}/>
      </div>
      <div style={{height: 48}}/>
      <h2 style={{textAlign: "center"}}>Total Sum</h2>
      <p style={{textAlign: "center"}}>Use scroll on chart to zoom in and move witch click & drag</p>
      <ChartSum options={optionsSum.toJS()}/>

      <div style={{height: 48}}/>
      <h2 style={{textAlign: "center", marginBottom: 0}}>Hourly count of events</h2>
      <p style={{textAlign: "center"}}>Use scroll on chart to zoom in and move witch click & drag</p>
      <ChartCount options={optionsCount.toJS()}/>

      <div style={{height: 48}}/>
      <h2 style={{textAlign: "center", marginBottom: 0}}>Hourly sum of $</h2>
      <p style={{textAlign: "center"}}>Use scroll on chart to zoom in and move witch click & drag</p>
      <ChartMoney options={optionsMoney.toJS()}/>

    </div>
  );
}

StatsTimeseries.propTypes = {
  user: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    ...statsSelector(state),
    ...userSelector(state),
    ...productSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    statsActions: bindActionCreators(statsActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StatsTimeseries);
