import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
// import {bindActionCreators} from 'redux';
import {selector as statsSelector} from 'features/stats';
import {Spin, Statistic, Card} from 'antd';

function ProductTotalSales({stats}) {
  const isLoading = stats.get('stats') === null;
  let totalSales = 0;
  let salesCount = 0;
  if (isLoading === false) {
    stats.get('stats').forEach((stat) => {
      totalSales += stat.get('priceForOne');
      salesCount++;
    });
  }

  return (
    <Card>
      <Statistic
        title={isLoading ? <Spin size={'small'}/> : `Sales (${salesCount})`}
        value={totalSales}
        precision={0}
        prefix="$"
      />
    </Card>
  );
}

ProductTotalSales.propTypes = {
  stats: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    ...statsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // productActions: bindActionCreators(productActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductTotalSales);
