import {createStructuredSelector} from 'reselect';

const {Map, fromJS} = require('immutable');

export const NAME = 'stats'; // name in redux store

const GET_TRY = 'stats/GET_TRY';
const GET_SUCCESS = 'stats/GET_SUCCESS';
const GET_FAILED = 'stats/GET_FAILED';

const initialState = Map({
  stats: null,
  isGetLoading: false,
});

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TRY:
      return state.set('isGetLoading', true);
    case GET_FAILED:
      return state.set('isGetLoading', false);
    case GET_SUCCESS:
      return state.set('isGetLoading', false).set('stats', fromJS(action.data));

    default:
      return state;
  }
}

// #### ACTIONS ####

function get({productIds}) {
  return {type: GET_TRY, data: {productIds}};
}

// #### SELECTORS ####
const stats = (state) => state[NAME];
const isStatsLoading = (state) => state[NAME].get('isGetLoading');

export const selector = createStructuredSelector({
  stats,
  isStatsLoading,
});

export const actionCreators = {
  get,
};

export const actionTypes = {
  GET_TRY,
  GET_SUCCESS,
  GET_FAILED,
};
