import {put} from "redux-saga/effects";
import {actionTypes as productTypes} from "features/product";
import {actionTypes as userTypes} from "features/user";
import sdk from "utils/getSdk";
import snackbar from "utils/snackbar";

export default function* tryProductsGet({data}) {

  const result = yield sdk.vendorListProducts({
    authToken: localStorage.getItem("authToken"),
    isVendorPortal: true,
  });

  if (result.error) {
    yield put({type: productTypes.LIST_FAILED, data: {error: result.error}});
    snackbar({message: "Failed to list your products", intent: "error"});
    yield put({type: userTypes.LOGOUT_TRY});
  } else {
    if (!result.data) {
      console.error(`#34rggehth FAILED to get product! `, result);
    } else {
      yield put({type: productTypes.LIST_SUCCESS, data: result.data});
    }
  }
}
