import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Layout, Button} from 'antd';
import {actionCreators as userActions, selector as userSelector} from 'features/user';

function NavigationTop({user, userActions}) {
  const isLoggedIn = user.getIn(['user', 'userId']);

  const menuItems = [];
  if (isLoggedIn) {
    menuItems.push(<Button key={'logout'} type={'primary'} onClick={() => userActions.logout({})}>Logout</Button>);
  } else {
    return null;
  }

  return (
    <Layout.Header style={{padding: 0}}>
      <div style={{padding: '0 24px', maxWidth: 1000, margin: '0 auto', textAlign: 'right'}}>
        {menuItems}
      </div>
    </Layout.Header>
  );
}

function mapStateToProps(state) {
  return {
    ...userSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavigationTop);
