import {put} from 'redux-saga/effects';
import {actionTypes as statsTypes} from 'features/stats';
import {actionTypes as userTypes} from 'features/user';
import sdk from 'utils/getSdk';
import snackbar from 'utils/snackbar';

export default function* tryStatsGet({data}) {
  // yield put({type: statsTypes.GET_TRY, data: {}});
  const result = yield sdk.vendorGetProductStats({
    authToken: localStorage.getItem('authToken'),
    productIds: data.productIds,
  });
  if (result.error) {
    yield put({type: statsTypes.GET_FAILED, data: {error: result.error}});
    snackbar({message: 'Failed to get stats for selected products', intent: 'error'});
    yield put({type: userTypes.LOGOUT_TRY});
  } else {
    if (!result.data) {
      console.error(`#fedsgwr FAILED to get stats! `, result);
    } else {
      yield put({type: statsTypes.GET_SUCCESS, data: result.data});
    }
  }
}
