import React from "react";

const moment = require("moment");
const numeral = require("numeral");

export default function PayoutStats({stats, user}) {

  // if (!user.getIn(["user", "email"]) || user.getIn(["user", "email"]).indexOf("@pitchground.com") === -1) {
  //   return null;
  // }

  const dealStartedAt = stats.getIn(["stats", 0, "dealStartedAt"]) || 0;

  const sorted = stats.get("stats").sort((x, y) => {
    if (x.get("paidAt") > y.get("paidAt")) {
      return 1;
    }
    if (x.get("paidAt") < y.get("paidAt")) {
      return -1;
    }
    return 0;
  });

  let intervalStartEndMap = {};
  let currentIntervalStartAt = dealStartedAt;
  let intervalInSeconds = 30 * 24 * 60 * 60; // 30d
  let intervalEndAt = dealStartedAt + intervalInSeconds;
  intervalStartEndMap[currentIntervalStartAt] = intervalEndAt;

  const intervals = {};
  sorted.forEach((item) => {
    if (item.get("paidAt") >= intervalEndAt) {
      // new interval.
      currentIntervalStartAt = intervalEndAt;
      intervalEndAt = currentIntervalStartAt + intervalInSeconds;
      intervalStartEndMap[currentIntervalStartAt] = intervalEndAt;
    }
    intervals[currentIntervalStartAt] = intervals[currentIntervalStartAt] || [];
    intervals[currentIntervalStartAt].push(item);
  });

  const rows = [];
  Object.keys(intervals).forEach((intervalStartAt) => {

    const intervalItems = intervals[intervalStartAt];

    let totalRefund = 0; // $
    let netSales = 0; // totalSales - totalRefund $

    intervalItems.forEach((item) => {
      if (item.get("state") === "refunded") {
        totalRefund += item.get("priceForOne");
      } else if (item.get("state") === "sold") {
        netSales += item.get("priceForOne");
      } else {
        console.error(`#202220111635269 unhandled state? `, JSON.stringify(item));
      }
    });

    const _40percentCents = Math.round(netSales * .4 * 100);
    let _firstHalfCents = Math.floor(_40percentCents / 2);
    let _secondHalfCents = _40percentCents - _firstHalfCents;

    const percentRaw = totalRefund / (netSales + totalRefund);
    let refundPercent = netSales + totalRefund ? numeral(percentRaw).format("0,0[.]000%") : "";
    let percentColor = percentRaw >= 0.15 ? "red" : "";
    rows.push(
      <div key={`intr-${intervalStartAt}`} style={{margin: "12px 0"}}>
        <div>
          <strong>30d. interval start: <strong>{moment.unix(intervalStartAt).format()}</strong>, end: <strong>{moment.unix(intervalStartEndMap[intervalStartAt]).format()}</strong></strong>
        </div>
        <div>Total Sales: ${numeral(netSales + totalRefund).format("0,0[.]00")}</div>
        <div>Total Refunds: ${numeral(totalRefund).format("0,0[.]00")} (<span style={{color: percentColor}}>{refundPercent}</span>)</div>
        <div>Balance: ${numeral(netSales).format("0,0[.]00")}</div>
        <div>40% of Balance: <strong>${numeral(_40percentCents / 100).format("0,0[.]00")}</strong> = ${numeral(_firstHalfCents / 100).format("0,0[.]00")} +
          ${numeral(_secondHalfCents / 100).format("0,0[.]00")}</div>
      </div>,
    );
  });

  return (
    <div>
      {rows}
    </div>
  );
}
